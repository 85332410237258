import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import Button from '@/components/Button/Button';
import Container from '@/components/Container/Container';
import PostItem from '@/components/PostItem/PostItem';
import { getPostsQuery } from '@/graphql/queries';

import styles from './PostList.module.sass';
import { ACTION, INITIAL_STATE, postsReducer, scrollToShowMore } from './utils';

const { query } = getPostsQuery();

const PostList = ({ list, pageInfo }) => {
  const [fetchMore, { loading }] = useLazyQuery(query);
  const [posts, dispatch] = useReducer(
    postsReducer,
    INITIAL_STATE({ pageInfo, postList: list })
  );
  const lastItemRef = useRef();

  const loadMore = useCallback(async () => {
    if (posts?.hasMore) {
      const { data } = await fetchMore({
        variables: { after: posts?.cursor, count: 12 },
      });

      dispatch({ payload: data?.posts, type: ACTION.UPDATE_LIST });
      setTimeout(scrollToShowMore, 250);
    }
  }, [fetchMore, posts?.cursor, posts?.hasMore]);

  useEffect(() => {
    const itemRef = lastItemRef.current;
    if (
      !('IntersectionObserver' in window) ||
      !('IntersectionObserverEntry' in window) ||
      !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
    ) {
      return;
    }
    const observer = new IntersectionObserver(([entry]) => {
      const isScrollingDown =
        entry.intersectionRect.top <= entry.boundingClientRect.top;

      if (entry.isIntersecting && isScrollingDown) {
        loadMore();
      }
    });
    if (itemRef) {
      observer.observe(itemRef);
    }

    return () => observer.unobserve(itemRef);
  }, [loadMore]);

  return (
    <section className={styles.postList}>
      <Container>
        {posts?.postList?.map((post, index, arr) => (
          <article
            key={post.id}
            {...(index === arr?.length - 1
              ? { ref: lastItemRef, 'data-last': 'true' }
              : {})}
          >
            <PostItem {...post} index={index} />
          </article>
        ))}
        {posts?.hasMore && (
          <div className={styles.loadMore}>
            {loading ? (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                alt="Loading..."
                height={44}
                src="/icons/loading.svg"
                width={44}
              />
            ) : (
              <Button onClick={loadMore}>I want more</Button>
            )}
          </div>
        )}
      </Container>
    </section>
  );
};

export default PostList;

PostList.propTypes = {
  list: PropTypes.array,
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    endCursor: PropTypes.string,
  }),
};
