import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

import client from '@/apollo-client';
import Container from '@/components/Container/Container';
import Header from '@/components/Header/Header';
import PostList from '@/components/PostList/PostList';
import Teaser from '@/components/Teaser/Teaser';
import { getPostsQuery } from '@/graphql/queries';
import styles from '@/styles/Home.module.sass';

export default function Home({ lastIssue, pageInfo, posts }) {
  return (
    <div className={styles.container}>
      <Head>
        <title>
          cake magazine - the indie, confused and sweet magazine | cake-mag.com
        </title>
      </Head>
      <Header />
      <Container>
        <Teaser {...lastIssue} />
        <PostList list={posts} pageInfo={pageInfo} />
      </Container>
    </div>
  );
}

export async function getStaticProps() {
  const { query, variables } = getPostsQuery({ count: 24 });
  const { data } = await client.query({ query, variables });
  const issueList = data?.categories?.nodes;
  const lastIssue = issueList?.[0];
  const { nodes: posts, pageInfo } = data?.posts;

  return {
    props: {
      issueList,
      lastIssue,
      pageInfo,
      posts,
    },
    revalidate: 30 * 60,
  };
}

Home.propTypes = {
  featuredImageUrl: PropTypes.string,
  lastIssue: PropTypes.shape({
    categoriesExtra: PropTypes.shape({
      buylink: PropTypes.string,
      buylinkalternative: PropTypes.string,
      featureimage: PropTypes.shape({
        sourceUrl: PropTypes.string,
      }),
    }),
    name: PropTypes.string,
    posts: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
          title: PropTypes.string,
        })
      ),
    }),
  }),
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    endCursor: PropTypes.string,
  }),
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      featuredImage: PropTypes.object,
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};
