import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';

import styles from './Teaser.module.sass';

export default function Teaser({ categoriesExtra, name, posts }) {
  const buyLink = categoriesExtra?.buylink;
  const buyLinkAlternative = categoriesExtra?.buylinkalternative;
  const featuredImage = categoriesExtra?.featureimage;
  const lastIssueName = posts?.nodes?.[0]?.title || name;

  return (
    <section className={styles.feature}>
      <div className={styles.imageWrapper}>
        <Image
          alt={lastIssueName}
          height={560}
          layout="responsive"
          priority
          src={featuredImage.sourceUrl}
          unoptimized
          width={400}
        />
      </div>
      <div className={styles.details}>
        <span>
          {name}
          <em>is out now!</em>
        </span>
        <div className={styles.buttons}>
          <Link href={posts.nodes[0].slug}>
            <a className={styles.button} href={posts.nodes[0].slug}>
              Check it out!
            </a>
          </Link>
          {buyLink && (
            <a
              className={styles.button}
              href={buyLink}
              target="_blank"
              rel="noreferrer"
            >
              Buy now!
            </a>
          )}
          {buyLinkAlternative && (
            <a
              className={styles.button}
              href={buyLinkAlternative}
              target="_blank"
              rel="noreferrer"
            >
              Buy this issue! <br />
              <small>(Alternative cover)</small>
            </a>
          )}
        </div>
      </div>
    </section>
  );
}

Teaser.propTypes = {
  categoriesExtra: PropTypes.shape({
    buylink: PropTypes.string,
    buylinkalternative: PropTypes.string,
    featureimage: PropTypes.shape({
      sourceUrl: PropTypes.string,
    }),
  }),
  name: PropTypes.string,
  posts: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  }),
};
